<template>
	<div class="bode">

		<div class="bode_left">
			<div class="main">
				<div class="container f-cb">
					<div class="leMenu">
						<div class="title">
							<h1>{{ introducedata.category }}</h1>
							<p></p>
						</div>
						<ul class="big" v-for="(item,key) in maincontentdata" :key="key">
							<li><a href="#" class="act" @click="onAct(item)">{{ item.headline }}</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="mess-w">
			<div class="bode_right" v-if="code==='0'">
				<div class="headmassage">
					<a>{{ introducedata.headline }}</a>
				</div>
				<div class="massage">
					<div style="width: 590pt;margin-bottom: 70pt;margin-top: 0pt;margin-left: 10pt;margin-right: 90.0pt;"
						v-html="introducedata.content">
					</div>
					<div style="padding: 0; text-align: right; margin-top: 15px; margin-bottom: 0;">
						{{ introducedata.source }}
					</div>
				</div>
			</div>

			<!-- 给个标识,让上面的或者下面的显示  -->
			<div class="bode_right" v-if="code==='1'">
				<div class="headmassage">
					<a>{{ introduceheadline }}</a>
				</div>
				<div class="massage">
					<ul class="zx_list">
						<li v-for="(item,key) in introducedatas" :key="key">
							<div class="zx_list_left">
								<img :src="item.img" alt="">
								<!-- http://www.chinaesa.org.cn/upload/201805/10/201805101936347575.jpg -->
							</div>
							<div class="zx_list_right">
								<h2><a href="">{{ item.title }}</a></h2>
								<p v-html="item.content">
								</p>
								<p>
									<span>{{ item.creatTime }}</span>
									<el-button style="float: right;padding: 6px;width: 100px;" type="primary"
										@click="manyclick(item)">查看更多
									</el-button>
								</p>
							</div>
							<div class="clear"></div>
						</li>
					</ul>
				</div>
			</div>

			<div v-if="details" class="bode_right" style="padding-top: 20px;">
				<el-page-header @back="goBack" :content="headline">
				</el-page-header>
				<div style="text-align: center;padding-top: 20px;font-weight: bold;font-size: 25px;">
					{{ title }}
				</div>
				<el-divider></el-divider>
				<div style="text-align: center;">
					<el-image :src="img" style="vertical-align: top;max-width: 500px;"></el-image>
				</div>
				<div style="padding-top: 25px;padding-bottom: 20px;" class="ql-editor" v-html="content"></div>
				<div style="font-weight: 600;text-align: right;padding-top: 25px;">{{ time }}</div>
				<div style="padding-top: 10px;text-align: right;padding-bottom: 25px;font-weight: 600;">{{ source }}
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		components: {
			// BaiduMap
		},
		props: ['maincontentdata'],
		data() {
			return {
				introduceheadline: '',
				maincontentdatas: {},
				code: '0',
				introducedata: {},
				introducedatas: [],
				details: false,
				title: '',
				content: '',
				time: '',
				headline: '',
				source: '',
				img: '',
				markerPoint: {
					lng: 116.4,
					lat: 39.9
				},
			}
		},
		methods: {
			onAct(data) {
				if (data.headline === "领导介绍") {
					this.$post("/industry/selectleader", {
						headline: data.headline
					}).then(resp => {
						this.introducedatas = resp.dataList
						this.introduceheadline = resp.dataList[0].headline
					})
					this.code = '1'
					this.details = false
				} else {
					this.$post("/industry/selectheadline",{headline: data.headline}).then(resp=>{
						this.introducedata = resp.data
					})
					this.code = '0'
					this.details = false
				}
			},
			manyclick(data) {
				this.details = true
				this.code = '2'
				this.title = data.title
				this.content = data.content
				this.time = data.creatTime
				this.headline = data.headline
				this.source = data.source
				this.img = data.img
			},
			goBack() {
				this.details = false
				this.code = '1'
			},
		},
		watch: {
			maincontentdata(newdata) {
				this.$post("/industry/selectheadline", {
					headline: newdata[0].headline
				}).then(resp => {
					this.introducedata = resp.data
				})
			}
		},
	}
</script>

<style scoped="scoped">
	@import '../../assets/css/cont.css';
	@import '../../assets/css/map/map.css';
	@import "~@/assets/css/richText.css";
	.zx_list_left img {
		width: 180px;
		height: 120px;
		transition: all 0.5s linear;
	}

	.zx_list li {
		border-bottom: 1px solid #EFF0EE;
		padding-bottom: 20px;
		margin-bottom: 30px;
	}

	.zx_list_right h2 {
		padding-bottom: 10px;
		font-weight: bold;
	}

	.zx_list {
		margin-top: 10px;
		margin-bottom: 20px;
	}

	.zx_list_right {
		width: 610px;
		float: right;
	}

	.zx_list_left {
		width: 180px;
		height: 120px;
		float: left;
		overflow: hidden;
	}

	.zx_list_right p:nth-of-type(1) {
		font-size: 12px;
		display: block;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
	}

	.zx_list_right p:nth-of-type(2) span {
		font-size: 12px;
	}

	.zx_list_right p a {
		float: right;
		background: #30302E;
		padding: 2px 15px;
		color: #fff;
	}

	.mess-text {
		text-align: left;
		margin: 20px auto;
		width: 699px
	}

	.mess-text p {
		margin-top: 5px;
		font-size: 16px;
	}
</style>
