<template>
	<div>
		<maincontent :maincontentdata="Profiledata"></maincontent>
	</div>
</template>

<script>
	import maincontent from  '@/components/common_components/miancontent.vue'
	
	export default {
		components: {
			maincontent
		},
		data() {
			return {
				Profiledata:[]
			}
		},
		created() {
			this.$post("/industry/categoryall",{category:"机构简介"}).then(resp=>{
				this.Profiledata = resp.dataList
			})
		},
	}
</script>

<style>
</style>
